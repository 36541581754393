import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, LoadingController, ModalController } from '@ionic/angular';

import { PLACEHOLDER_IMAGE_SRC } from 'src/app/constants';
import { Automation, AUTOMATION_TRIGGER_TYPES } from 'src/models';
import { ApiService } from 'src/services/api.service';
import { AutomationsService } from 'src/services/automations.service';
import { MessageService } from 'src/services/message.service';
import { SearchablePopoverService } from 'src/services/searchable-popover.service';
import { UploadService } from 'src/services/upload.service';

import { GiphyModalComponent } from '../../giphy-modal/giphy-modal.component';
import { GiphyModalModule } from '../../giphy-modal/giphy-modal.module';
import { ImageModalComponent } from '../../image-modal/image-modal.component';
import { SlackTextEditorComponent } from '../../slack-text-editor/slack-text-editor.component';
import { Block, isButtons, isContext, isRichText, isSection, SurveyBlock } from '../common';
import { SlackMarkdown } from '../slack-markdown';
import { SlackSurveyPreviewComponent } from '../slack-survey-preview/slack-survey-preview.component';

// Extended button interface to include actionType for UI purposes
interface ExtendedButtonElement {
  type: 'button';
  text: {
    type: string;
    text: string;
    emoji: boolean;
  };
  style?: string;
  value?: string;
  url?: string;
  automationId?: string;
  automationName?: string;
  actionType?: 'url' | 'automation';
}

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, GiphyModalModule, SlackTextEditorComponent],
  selector: 'app-slack-block-edit',
  templateUrl: './slack-block-edit.component.html',
  styleUrls: ['./slack-block-edit.component.scss'],
})
export class SlackBlockEditComponent implements OnInit {
  @Input() block: any;
  @Input() slackMarkdown: SlackMarkdown;
  @Output() blockChanged = new EventEmitter<Block>();

  public error?: string;
  public htmlStrings: Record<number, string> = {};

  constructor(
    private modalCtrl: ModalController,
    private uploadSrvc: UploadService,
    private loadingCtrl: LoadingController,
    private msgSrvc: MessageService,
    private automationsService: AutomationsService,
    private searchablePopoverService: SearchablePopoverService,
  ) {}

  ngOnInit() {
    this.setHtmlStrings();

    // Initialize actionType for existing buttons
    if (isButtons(this.block)) {
      this.block.elements.forEach(async (button: ExtendedButtonElement) => {
        if (button.automationId) {
          button.actionType = 'automation';

          const automation = await this.automationsService.getById(button.automationId);

          button.automationName = automation.name;
        } else {
          button.actionType = 'url';
        }
      });
    }
  }

  setHtmlStrings() {
    this.htmlStrings = {};

    if (isRichText(this.block)) {
      this.htmlStrings = [this.block.htmlContent];
    } else if (isSection(this.block)) {
      this.htmlStrings[0] = this.block.htmlContent;
    } else if (isContext(this.block)) {
      for (const [i, element] of this.block.elements.entries()) {
        if (element.type === 'text') {
          this.htmlStrings[i] = element.htmlContent;
        }
      }
    }
  }

  onButtonActionTypeChange(button: ExtendedButtonElement) {
    if (button.actionType === 'url') {
      button.automationId = undefined;
      button.automationName = undefined;
      if (!button.url) {
        button.url = 'https://';
      }
    } else if (button.actionType === 'automation') {
      button.url = undefined;
    }

    this.onChange();
  }

  toggleImageAccessory() {
    if (isSection(this.block)) {
      if (this.block.accessory) {
        delete this.block.accessory;
      } else {
        this.block.accessory = {
          type: 'image',
          image_url: PLACEHOLDER_IMAGE_SRC,
          alt_text: 'My image',
        };
      }

      this.onChange();
    }
  }

  async addImageAccessory() {
    const isAddingSectionAccessoryImage = isSection(this.block);
    const componentProps: any = {
      searchValue: '',
      helperText: '',
      showStockPhotos: true,
    };

    if (isAddingSectionAccessoryImage) {
      componentProps.aspectRatio = 1;
    }
    const modal = await this.modalCtrl.create({
      component: ImageModalComponent,
      componentProps,
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    // if backdrop is clicked and no data passed
    if (!data || !data.uploadFile) {
      return;
    }

    const loader = await this.loadingCtrl.create({
      message: 'Uploading...',
      spinner: 'crescent',
      duration: 10000,
    });
    loader.present();

    this.uploadSrvc
      .upload([data.uploadFile], `slack`)
      .then(() => {
        loader.dismiss();
        const imageUrl = data.uploadFile.url;

        if (isAddingSectionAccessoryImage) {
          this.block.accessory = {
            type: 'image',
            image_url: imageUrl,
            alt_text: data.fileName || 'image',
          };
        } else {
          this.block.image_url = imageUrl;
          this.block.alt_text = data.fileName || 'image';
        }

        this.onChange();
      })
      .catch((err) => {
        loader.dismiss();
        this.msgSrvc.show(err.message);
      });
  }

  async addGif() {
    const modal = await this.modalCtrl.create({
      component: GiphyModalComponent,
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data.url) {
      this.block.image_url = data.url;
      this.block.alt_text = data.title || 'image';
    }

    this.onChange();
  }

  removeAccessory() {
    if (isSection(this.block)) {
      this.block.accessory = undefined;
      this.onChange();
    }
  }

  addTextElement() {
    if (isContext(this.block)) {
      this.block.elements.push({
        type: 'text',
        htmlContent: 'Context goes here',
      });

      this.onChange();
    }
  }

  addImageElement() {
    if (isContext(this.block)) {
      this.block.elements.push({
        type: 'image',
        image_url: PLACEHOLDER_IMAGE_SRC,
        alt_text: '',
      });

      this.onChange();
    }
  }

  addButtonElement() {
    this.block.elements.push({
      type: 'button',
      actionType: 'url',
      text: {
        type: 'plain_text',
        text: 'Button label',
        emoji: true,
      },
      value: 'click_me_123',
      url: 'https://',
    });

    this.onChange();
  }

  removeElement(i: number) {
    if (isContext(this.block) || isButtons(this.block)) {
      this.block.elements.splice(i, 1);

      this.setHtmlStrings();
      this.onChange();
    }
  }

  getElementTitle(element: any) {
    if (element.type === 'image') {
      return 'Image';
    }

    return 'Text';
  }

  onContextTextElementChange(i: number, event) {
    this.block.elements[i].htmlContent = event;
    this.blockChanged.emit(this.block);
  }

  async viewSurveyPreview(block: SurveyBlock) {
    const surveyPreviewModal = await this.modalCtrl.create({
      component: SlackSurveyPreviewComponent,
      componentProps: {
        survey: block.survey,
      },
    });
    await surveyPreviewModal.present();
  }

  onChange() {
    this.blockChanged.emit(this.block);
  }

  onHtmlContentChange(event) {
    this.block.htmlContent = event;
    this.blockChanged.emit(this.block);
  }

  setButtonStyle(button: any, style: string) {
    button.style = style;

    this.onChange();
  }

  selectAutomation(button: ExtendedButtonElement) {
    this.searchablePopoverService.automations({
      event,
      trigger: {
        type: ['manual', 'slackReaction'],
      },
      callback: (id: string, name: string, data: Automation) => {
        if (!id) {
          return;
        }

        button.automationId = id;
        button.automationName = name || data.name;

        this.onChange();
      },
    });
  }
}
